import React from 'react'
import logo from "../assets/Logo.png"
import { Link, matchPath, useLocation } from 'react-router-dom'
import { NavbarLinks } from '../data/NavbarLinks'

const Navbar = () => {
    const location = useLocation();
    const currentNav = [
        {
            
        }, {
          color : "#4bc955"
        }
      ]

      const matchRoute = (route) => {
        return matchPath({ path: route }, location.pathname);
      };
  return (
    <div className='navbar-wrapper'>
      <div className='navbar'>
        <div className='navbar-left'>
            <img src={logo} alt='Logo'/>
        </div>
        <div className='navbar-right'>
            {
                NavbarLinks.map((navlink, index) => {
                    return (
                        <Link style={matchRoute(navlink.path) ? (currentNav[1]) : (currentNav[0])} key={index} to={navlink.path}>{navlink.title}</Link>
                    )
                })
            }
        </div>
      </div>
    </div>
  )
}

export default Navbar
