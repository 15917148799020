import React from 'react'
import { MdOutlineManageAccounts } from "react-icons/md";
import { MdAssuredWorkload } from "react-icons/md";
import { MdOutlineAttachMoney } from "react-icons/md";
import { BsBuildingsFill } from "react-icons/bs";
import { AiOutlineAim } from "react-icons/ai";
import { FaCoins } from "react-icons/fa";
import { BsGraphUpArrow } from "react-icons/bs";
import { Link } from 'react-router-dom';

const OurServices = () => {
  return (
    <div className='our-services-wrapper'>
      <div className='our-services'>
        <div className='our-services-heading'>Our Services</div>
        <div className='our-services-boxes'>
            <Link style={{textDecoration : "none", color: "black"}} to={"/services"} className='our-services-box-1'>
                <MdAssuredWorkload size={40} color={"#4bc955"}/>
                Banking Sector
            </Link>
            <Link style={{textDecoration : "none", color: "black"}} to={"/services"} className='our-services-box-1'>
                <MdOutlineManageAccounts size={40} color={"#4bc955"}/>
                Fraud Detection/ Investigation
            </Link>
            <Link style={{textDecoration : "none", color: "black"}} to={"/services"} className='our-services-box-1'>
                <MdOutlineAttachMoney size={40} color={"#4bc955"}/>
                Auditing & Assurance Services
            </Link>
            <Link style={{textDecoration : "none", color: "black"}} to={"/services"} className='our-services-box-1'>
                <BsBuildingsFill size={40} color={"#4bc955"}/>
                Entity Formation Services
            </Link>
        </div>
        <div className='our-services-boxes'>
        <Link style={{textDecoration : "none", color: "black"}} to={"/services"} className='our-services-box-2'>
                <AiOutlineAim size={40} color={"#4bc955"}/>
                Taxation Consultancy, Compliance and Representation/Litigation support
            </Link>
            <Link style={{textDecoration : "none", color: "black"}} to={"/services"} className='our-services-box-2'>
                <FaCoins size={40} color={"#4bc955"}/>
                Business Consultancy and Business Intelligence Services
            </Link>
            <Link style={{textDecoration : "none", color: "black"}} to={"/services"} className='our-services-box-2'>
                <BsGraphUpArrow size={40} color={"#4bc955"}/>
                Designing, Implementation and Maintenance of an Internal Control
            </Link>
        </div>
      </div>
    </div>
  ) 
}

export default OurServices
