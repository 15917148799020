import React from 'react'
import Slider from '../components/Slider'
import OurServices from '../components/OurServices'
import Aboutus from '../components/Aboutus'

const Home = () => {
  return (
    <div>
      <Slider/>
      <OurServices />
      <Aboutus />
      {/* <div className='spacer'>

      </div> */}
    </div>
  )
}

export default Home
