import React from "react";
import { RiArrowRightDoubleFill } from "react-icons/ri";

const About = () => {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
  return (
    <div className="about-us-wrapper">
      <div className="about-hero-wrapper">
        <span>About The Firm</span>
      </div>
      <div className="about-us">
        <div className="about-us-upper">
          <div className="about-us-upper-box">
            <h1 className="about-us-upper-box-heading">About Us</h1>
            <p className="about-us-upper-box-para">
              <b>M S Joshi & Co.,</b> is a Chartered Accountancy Firm,
              established and registered with The{" "}
              <b>Institute of Chartered Accountants of India (ICAI)</b> since
              November, 2013.
              <br />
              <br /> The firm was erstwhile child firm of partnership firm; M/s
              Joshi Jain & Associates as per merger agreement, which got
              demerged with effective from December, 2016
              <br/><br />
              The firm got its Peer Review Certification in year 2021 from Institute of Chartered Accountants of India
(ICAI). The firm is currently empaneled with CAG (Comptroller and Auditor General of India). 
            </p>
          </div>
          <div className="about-us-upper-box">
            <h1 className="about-us-upper-box-heading">Vision</h1>
            <p className="about-us-upper-box-para">
              To be recognised as a leading professional firm in country 
              with presence in multiple states, by virtue of sheer
              professionalism, values and ethics, having one-stop solution
              approach with field excellence.
            </p>
          </div>
          <div className="about-us-upper-box">
            <h1 className="about-us-upper-box-heading">Mission</h1>
            <p className="about-us-upper-box-para">
              To develop enduring relationships with the clients by offering
              comprehensive range of professional services with best suited
              tailor made offerings; keeping in mind past trends, present
              requirements and future needs. <br /> <br /> To create an
              inclusive and diverse workplace culture while supporting employees
              to thrive professionally and personally, by encouraging and giving
              them opportunity to develop and grow skill horizon along with
              offering monetary incentives to acknowledge their contribution in
              growth of firm.
            </p>
          </div>
          <div className="about-us-upper-box">
            <h1 className="about-us-upper-box-heading">Values</h1>
            <p className="about-us-upper-box-para">
              Excellence, Independence & Integrity.
            </p>
          </div>
          <div className="about-us-upper-box">
            <h1 className="about-us-upper-box-heading">Team</h1>
            <p className="about-us-upper-box-para">
              <b>M S Joshi & Co.</b> is a virtual melting plot of thriving minds
              from variety of disciplines. Partners and Audit team members
              (including Article/Audit Assistants and other
              semi/non-professional audit staff) are working with an underlying
              passion for professional excellence.
              <br />
              <br /> Firm’s multi-skilled partners and staff members are working
              in high level of office automation. To attain optimum efficiency;
              we at M S Joshi & Co.; follow a non-hierarchical work culture and
              an open office plan, facilitating a free flow of interaction
              between and within groups.
            </p>
          </div>
        </div>
        <div className="about-us-lower">
          <h1 className="about-us-lower-heading">About The Partners</h1>
          <div className="about-us-lower-box">
            <div className="about-us-partner-profile">
              <h1>CA MEHULKUMAR JOSHI</h1>
              <ul>
                <li>- FELLOW CHARTERED ACCOUNTANT</li>
                <li>- BACHELOER OF COMMERCE</li>
                <li>- DIPLOMA IN INFORMATION SYSTEMS AUDIT (ICAI)</li>
                <li>- PEER REVIEWER (ICAI)</li>
                <li>- CERTIFIED CONCURRENT AUDITOR OF BANKS (ICAI)</li>
                <li>- SOCIAL AUDITOR</li>
                <li>- DIP-IFR (ACCA) (PERSUING)</li>
              </ul>
            </div>
            <div className="about-us-partner-details">
              <ul>
                <li><RiArrowRightDoubleFill size={20}/> CA Mehul Joshi has completed his graduation in Commerce discipline from Veer Narmad South Gujarat University in 2011 and has qualified as a Chartered Accountant in year 2012.</li>
                <li><RiArrowRightDoubleFill size={20}/> After completing his internship from Firm M/s Vora Morakhia & Co, he started his own practice in year 2013. Alongside, he initially has joined a firm M/s Mitul Mehta & Co. as a partner in year 2013 and served till year 2015. Afterwards he has merged his firm in partnership with M/s Joshi Jain & Associates in year 2015, which got demerged in year 2016.</li>
                <li><RiArrowRightDoubleFill size={20}/> He is having vast experience in Bank Audits, Direct Taxation and Management consultancy of over a decade.</li>
              </ul>
            </div>
          </div>
          <div className="about-us-lower-box">
            <div className="about-us-partner-profile">
              <h1>CA AMAR S. SHAH</h1>
              <ul>
                <li>- FELLOW CHARTERED ACCOUNTANT</li>
                <li>- BACHELOER OF COMMERCE</li>
              </ul>
            </div>
            <div className="about-us-partner-details">
              <ul>
                <li><RiArrowRightDoubleFill size={20}/> CA Amar Shah has completed his graduation in Commerce discipline from Veer Narmad South Gujarat University in 2015 and has qualified as a Chartered Accountant in year 2017.</li>
                <li><RiArrowRightDoubleFill size={20}/> After completing his internship from Firm M/s Vora Morakhia & Co, he started his own practice in year 2017.</li>
                <li><RiArrowRightDoubleFill size={20}/> He is having vast experience in Indirect Taxation and Financial consultancy.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
