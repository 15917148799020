import React from "react";
import { RiArrowRightDoubleFill } from "react-icons/ri";

const Services = () => {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
  return (
    <div className="services-section-wrapper">
      <div className="services-hero-wrapper">
        <span>Our Services</span>
      </div>
      <div className="services-wrapper">
        <h1 className="services-heading">Services</h1>
        <div className="services">
          <h2>Banking Sector</h2>
          <p>
            <RiArrowRightDoubleFill /> Statutory Audit
          </p>
          <p>
            <RiArrowRightDoubleFill /> Concurrent Audit
          </p>
          <p>
            <RiArrowRightDoubleFill /> Stock and Receivable Audit
          </p>
          <p>
            <RiArrowRightDoubleFill /> Credit Audit
          </p>
          <p>
            <RiArrowRightDoubleFill /> Revenue Audit
          </p>
          <p>
            <RiArrowRightDoubleFill /> Due Diligence Assessment
          </p>
          <p>
            <RiArrowRightDoubleFill /> Credit Verification of Business Loans
          </p>
          <p>
            <RiArrowRightDoubleFill /> Income Estimation for sub-prime
            Mortgage/Personal loans lending
          </p>
          <p>
            <RiArrowRightDoubleFill /> Personal Discussion (PD) of credit
            applicants
          </p>
          <p>
            <RiArrowRightDoubleFill /> Credit Appraisals
          </p>
        </div>
        <div className="services">
          <h2>
            Taxation Consultancy, Compliance and Representation / Litigation
            support
          </h2>
          <p>
            <RiArrowRightDoubleFill /> Company Law matters
          </p>
          <p>
            <RiArrowRightDoubleFill /> Income Tax matters
          </p>
          <p>
            <RiArrowRightDoubleFill /> Goods and Service Tax matters
          </p>
          <p>
            <RiArrowRightDoubleFill /> State VAT, Excise, Customs and Service
            Tax matters
          </p>
          <p>
            <RiArrowRightDoubleFill /> International Taxation and Transfer
            Pricing matters
          </p>
        </div>
        <div className="services">
          <h2>Auditing & Assurance Services</h2>
          <p>
            <RiArrowRightDoubleFill /> Tax Audit
          </p>
          <p>
            <RiArrowRightDoubleFill /> Companies Audit
          </p>
          <p>
            <RiArrowRightDoubleFill /> Limited Liabilities Partnerships Audit
          </p>
          <p>
            <RiArrowRightDoubleFill /> Trust Audit 
          </p>
          <p>
            <RiArrowRightDoubleFill /> Internal Audit
          </p>
          <p>
            <RiArrowRightDoubleFill /> Stock/ Inventory Audit
          </p>
          <p>
            <RiArrowRightDoubleFill /> Cost Audit
          </p>
          <p>
            <RiArrowRightDoubleFill /> Co-Operative Societies Audit
          </p>
          <p>
            <RiArrowRightDoubleFill /> Management Audit/ Proprietary Audit
          </p>
          <p>
            <RiArrowRightDoubleFill /> Efficiency Audit
          </p>
          <p>
            <RiArrowRightDoubleFill /> Control Audit
          </p>
          <p>
            <RiArrowRightDoubleFill /> Forensic Audit
          </p>
          <p>
            <RiArrowRightDoubleFill /> Tailor-made voluntary Audit
          </p>
        </div>
        <div className="services">
          <h2>Business Consultancy and Business Intelligence Services</h2>
          <p>
            <RiArrowRightDoubleFill /> Book-keeping and Accounting outsourcing
            (KPO) in developed nations
          </p>
          <p>
            <RiArrowRightDoubleFill /> Implementation of Ind-AS/IFRS in
            Financial Statements
          </p>
          <p>
            <RiArrowRightDoubleFill /> SOP Development and Monitoring
          </p>
          <p>
            <RiArrowRightDoubleFill /> Business Impact Assessment
          </p>
          <p>
            <RiArrowRightDoubleFill /> BCP Drafting & Implementation Strategy
          </p>
          <p>
            <RiArrowRightDoubleFill /> Management Consultancy
          </p>
          <p>
            <RiArrowRightDoubleFill /> Financial Consultancy
          </p>
          <p>
            <RiArrowRightDoubleFill /> Retirement/ Strategic Planning
          </p>
          <p>
            <RiArrowRightDoubleFill /> Business Start-up Consultancy
          </p>
          <p>
            <RiArrowRightDoubleFill /> Resource and capacity assessment
          </p>
          <p>
            <RiArrowRightDoubleFill /> Systems and Software Remediation
          </p>
        </div>
        <div className="services">
          <h2>Fraud Detection/ Investigation</h2>
          <p>
            <RiArrowRightDoubleFill /> Funds siphoning & Banking frauds
          </p>
          <p>
            <RiArrowRightDoubleFill /> Tendering and Bid selection frauds
          </p>
          <p>
            <RiArrowRightDoubleFill /> Vendor payment frauds
          </p>
          <p>
            <RiArrowRightDoubleFill /> Employment frauds
          </p>
          <p>
            <RiArrowRightDoubleFill /> Inventory frauds
          </p>
          <p>
            <RiArrowRightDoubleFill /> Information System frauds
          </p>
          <p>
            <RiArrowRightDoubleFill /> Financial frauds
          </p>
          <p>
            <RiArrowRightDoubleFill /> Procurement and Consumption frauds
          </p>
          <p>
            <RiArrowRightDoubleFill /> Sales Ordering and Delivery frauds
          </p>
        </div>
        <div className="services">
          <h2>Entity Formation Services</h2>
          <p>
            <RiArrowRightDoubleFill /> Company Incorporation
          </p>
          <p>
            <RiArrowRightDoubleFill /> Limited Liability Partnership
            Incorporation
          </p>
          <p>
            <RiArrowRightDoubleFill /> Partnership Firm Formation and
            Registration
          </p>
          <p>
            <RiArrowRightDoubleFill /> Charitable Trust Formation as per Bombay
            Public Trust Act
          </p>
          <p>
            <RiArrowRightDoubleFill /> Partnership Firm Registration with Registrar Of Firms (ROF)
          </p>
          <p>
            <RiArrowRightDoubleFill /> Section 8 Company Incorporation
          </p>
          <p>
            <RiArrowRightDoubleFill /> Insurance Brokers and NBFC Registration
          </p>
          <p>
            <RiArrowRightDoubleFill /> Private Trust Formation
          </p>
          <p>
            <RiArrowRightDoubleFill /> Society Formation
          </p>
          <p>
            <RiArrowRightDoubleFill /> Co-Operative Housing Society Registration
          </p>
          <p>
            <RiArrowRightDoubleFill /> Registration of Charitable Trusts u/s
            12AB of Income Tax Act
          </p>
          <p>
            <RiArrowRightDoubleFill /> Registration of Charitable Trusts u/s 80G
            of Income Tax Act
          </p>
          <p>
            <RiArrowRightDoubleFill /> FCRA Registration
          </p>
          <p>
            <RiArrowRightDoubleFill /> Darpan Registration
          </p>
          <p>
            <RiArrowRightDoubleFill /> PSARA Registration
          </p>
        </div>
        <div className="services">
          <h2>
            Designing, Implementation and Maintenance of an Internal Control
          </h2>
          <h4>Designing & Implementation :</h4>
          <span>
            In determining the policies with regard to internal control, and
            thereby assessing what constitutes a sound system of internal
            control in the particular circumstances of the client, we adhere to
            following factors
          </span>
          <p>
            <RiArrowRightDoubleFill /> The nature and extent of the risks facing
            the client
          </p>
          <p>
            <RiArrowRightDoubleFill /> The extent and categories of risk which
            it regards as acceptable for the client to bear
          </p>
          <p>
            <RiArrowRightDoubleFill /> The likelihood of the risks concerned
            materializing
          </p>
          <p>
            <RiArrowRightDoubleFill /> The client’s ability to reduce the impact
            on the business of risks that do materialize
          </p>
          <p>
            <RiArrowRightDoubleFill /> The costs of operating particular
            controls relative to the benefit thereby obtained in managing the
            related risks.
          </p>
          <h4>Operation & Maintenance :</h4>
          <span>
            The operation and monitoring of the system of internal control is
            undertaken by the team of experts; who possess the necessary skills,
            technical knowledge, objectivity, and understanding of the client
            and the industries and markets in which it operates.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Services;
