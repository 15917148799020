import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Navbar from './components/Navbar';
import Implinks from './pages/Implinks';
import About from './pages/About';
import Services from './pages/Services';
import Footer from './components/Footer';

function App() {
  return (
    <div className='container'>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/services' element={<Services />} />
        <Route path='/importantlinks' element={<Implinks />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
