export const NavbarLinks = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "Who We Are",
      path: '/about',
    },
    {
      title: "What We do",
      path: "/services",
    },
    {
      title: "Important Links",
      path: "/importantlinks",
    }
  ];