import React, { useState } from "react";
import { CiMobile3 } from "react-icons/ci";
import { MdOutlineMail } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import toast from "react-hot-toast";
import { contactusEndpoint } from '../services/apis';
import { apiConnector } from "../services/apiconnector";
import { FaLocationDot } from "react-icons/fa6";

const Aboutus = () => {

  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    message: "",
  });

  const { firstName, email, message } = formData;

  const handleOnChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleOnSubmit = async(e) => {
    e.preventDefault();

    const toastId = toast.loading("Loading...");
    try {
      const response = await apiConnector(
        "POST",
        contactusEndpoint.CONTACT_US_API,
        formData
      )

      if (!response.data?.success) {
        toast.dismiss(toastId);
        return
      }

      toast.success("Message Sent Successfully");

    } catch (error) {
      console.log("ERROR MESSAGE - ", error.message)
      error.response.data.message ? toast.error(error.response.data.message) : toast.error(error)
    }
    toast.dismiss(toastId);

    // Reset
    setFormData({
      firstName: "",
      email: "",
      message: "",
    });
  };


  return (
    <div id="contact" className="about-wrapper">
      <div className="about">
        <div className="about-heading">Contact Us</div>
        <div className="about-details">
          <div className="about-details-box">
            <div className="about-details-box-logo">
              <CiMobile3 size={35} />
            </div>
            <p><b>CA Mehul Joshi:</b> +91 93741 36905</p>
            <p><b>Office:</b> +91 96240 94390</p>
          </div>
          <div className="about-details-box">
            <div className="about-details-box-logo">
              <MdOutlineMail size={35} />
            </div>
            <p><b>Primary Email:</b> connect@msjc.in</p>
            <p><b>Secondary Email:</b> msjoshiandco@gmail.com</p>
          </div>
        </div>
        <div className="about-details-form">
          <div id="address-box" className="about-details-box">
            <div className="about-details-box-logo">
              <IoLocationSharp size={35} />
            </div>
            <p>
              <b>Head Office:</b> 725, Ajanta Shopping Centre, Above Havmor Mobile,
              Near Kinnary Multiplex, Ring Road, Surat, Gujarat – 395003 (India)
            </p>
            <a style={{color:"blue" , textDecoration:"none"}} href="https://www.google.com/maps/place/Ajanta+Shopping+Centre/@21.185554,72.8332551,17z/data=!4m14!1m7!3m6!1s0x3be04e445a9369f1:0xe5e8355fff68d93c!2sAjanta+Shopping+Centre!8m2!3d21.185549!4d72.83583!16s%2Fg%2F11c0tb2pgt!3m5!1s0x3be04e445a9369f1:0xe5e8355fff68d93c!8m2!3d21.185549!4d72.83583!16s%2Fg%2F11c0tb2pgt?entry=ttu"><FaLocationDot /> Click Here to visit</a>
            <p>
              <b>Branch Office:</b> 3, Swami Sahjanand Park Soc., Babuniwas ni Gali,
              Timliyawad, Nanpura, Surat, Gujarat – 395001 (India)
            </p>
          </div>
            <form className="contact-form" onSubmit={handleOnSubmit}>
              <h1 className="contact-form-heading">Get In Touch</h1>
              <p>We'd love to here for you, Please fill out this form.</p>
              <input
                required
                type="text"
                name="firstName"
                value={firstName}
                onChange={handleOnChange}
                placeholder="Enter your Name"
                className="signup-firstname"
              ></input>
              <input
                required
                type="text"
                name="email"
                value={email}
                onChange={handleOnChange}
                placeholder="Email"
              ></input>
              <textarea
                name="message"
                value={message}
                onChange={handleOnChange}
                placeholder="Enter your message here..."
              />
              <button className="special-btn" type="submit">
                Send Message
              </button>
            </form>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
