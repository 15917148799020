import React from 'react'
import logo from "../assets/Logo.png"
import { FaGoogle } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaLinkedin } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='footer-wrapper'>
    <div className='footer'>
      <div className='footer-left'>
        <img src={logo} alt=''></img>
        <p>Your Trusted Path to Financial Excellence.</p>
      </div>
      <div className='footer-middle'>
        <h3>Quick Links</h3>
        <Link to={"/"}>Home</Link>
        <Link to={"/about"}>Who We Are</Link>
        <Link to={"/services"}>What We do</Link>
        <Link to={"/importantlinks"}>Important Links</Link>
        <div>Photos are Designed by <a style={{color:"blue"}} href='https://www.freepik.com/'>Freepik</a></div>
      </div>
      <div className='footer-right'>
        <h3>Follow Us</h3>
        <div className='footer-logos'>
            <span><a style={{color: 'black'}} href="https://www.google.com/"><FaGoogle size={30}/></a></span>
            <span><a style={{color: 'black'}} href="https://wa.me/919624094390"><IoLogoWhatsapp size={30}/></a></span>
            <span><a style={{color: 'black'}} href="https://www.linkedin.com/in/msjcdotin/"><FaLinkedin size={30}/></a></span>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Footer
