import React from 'react'
import link1 from '../assets/link1.jpg'
import link2 from '../assets/link2.jpg'
import link3 from '../assets/link3.jpg'
import link4 from '../assets/link4.jpg'
import link5 from '../assets/link5.jpg'
import link6 from '../assets/link6.jpg'
import link7 from '../assets/link7.png'
import link8 from '../assets/link8.png'
import link9 from '../assets/link9.png'
import link10 from '../assets/link10.png'
import link11 from '../assets/link11.jpeg'
import link12 from '../assets/link12.png'

const Implinks = () => {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
  return (
    <div className='implinks-wrapper'>
      <h1>Links for Important Website</h1>
      <div className='implinks'>
        <div className='implink'>
          
          <a href='https://www.gst.gov.in'><img src={link1} alt="" /></a>
        </div>
        <div className='implink'>
          
          <a href='https://www.icai.org/'><img src={link2} alt="" /></a>
        </div>
        <div className='implink'>
          
          <a href='https://cbic.gov.in/'><img src={link3} alt="" /></a>
        </div>
        <div className='implink'>
          
          <a href='https://ibbi.gov.in/en'><img src={link4} alt="" /></a>
        </div>
        <div className='implink'>
          
          <a href='https://www.incometaxindiaefiling.gov.in/'><img src={link5} alt="" /></a>
        </div>
        <div className='implink'>
          
          <a href='http://mca21.gov.in/'><img src={link6} alt="" /></a>
        </div>
        <div className='implink'>
          
          <a href='https://icmai.in/icmai/index.php'><img src={link7} alt="" /></a>
        </div>
        <div className='implink'>
         
          <a href='https://www.rbi.org.in/'> <img src={link8} alt="" /></a>
        </div>
        <div className='implink'>
          
          <a href='https://www.sebi.gov.in/'><img src={link9} alt="" /></a>
        </div>
        <div className='implink'>
          
          <a href='https://contents.tdscpc.gov.in/'><img src={link10} alt="" /></a>
        </div>
        <div className='implink'>
          
          <a href='https://www.icsi.edu/home/'><img src={link11} alt="" /></a>
        </div>
        <div className='implink'>
          
          <a href='https://ewaybill.nic.in/'><img src={link12} alt="" /></a>
        </div>
      </div>
    </div>
  )
}

export default Implinks
